
@import '@design';

.o-notifications-api {
    width: 100%;
}

.o-notifications-api-wrap {
    position: fixed;
    right: 3px;
    bottom: 3px;
    z-index: 999;
}

.vue-notification-group {
    position: static !important;
}

.vue-notification-template {
    padding: 12px 18px;
    margin: 25px;
    font-size: 14px;
    background: linear-gradient(
            0deg,
            rgba(2, 36, 156, 0.1),
            rgba(2, 36, 156, 0.1)
        ),
        #ffffff;
    border: none;
    border-radius: 8px;
    box-shadow: 4px 4px 24px rgba(51, 51, 51, 0.08);

    .title {
        display: block;
        margin-bottom: 10px;
        line-height: 17px;
        word-break: break-word;
    }

    .text {
        line-height: 16px;
        word-break: break-word;
        a {
            color: $color-blue;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .button {
        .cancel-btn {
            cursor: pointer;
            font-weight: 600;
            text-decoration: underline;
            padding-left: 5px;
        }
    }

    &.info {
        display: flex;
        justify-content: space-between;
        color: $color-blue;
    }

    &.error {
        color: #d84f4d;
        background: #fbd6d5;
        box-shadow: 0 0 4px rgba(255, 62, 62, 0.4);
    }
    .close {
        position: relative;
        top: -1px;
        width: 8px;
        height: 8px;
        margin-left: 14px;
        border: none;
        &::after,
        &::before {
            position: absolute;
            width: 2px;
            height: 12px;
            content: '';
            background-color: $color-blue;
        }
        &::before {
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
    }

    .close-error {
        position: relative;
        top: -10px;
        width: 8px;
        height: 8px;
        margin-left: 14px;
        border: none;
        background: #fbd6d5;
        &::after,
        &::before {
            position: absolute;
            width: 2px;
            height: 12px;
            content: '';
            background-color: #d84f4d;
        }
        &::before {
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
    }
}
