//COLORS
$color-text-grey: #333;
$color-light-grey: #999;
$color-transparent-grey: rgba(51, 51, 51, 0.6);
$color-blue: #02249C;
$color-transparent-blue: #02249C1A;
$color-white: #FFF;
$color-red: #D73250;
$color-text-red: #FD6377;


// Borders
$color-border-light-blue: #02249C33;
$color-border-red: #FEB1BB;
$color-border-grey: #5C5C5C;


// BG
$color-body-bg: #F5F5F5;
$color-default-bg: $color-white;
$color-lite-blue-bg: #02249C1A;
$color-table-lite-blue-bg: #02249C0D;
$color-gray-bg: #d6d6d6;
