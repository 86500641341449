$system-default-font-family: 'Montserrat', sans-serif;


$font-weight-lighter: 100;
$font-weight-light: 300;
$font-weight-medium-s: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-extra-bold: 800;
$font-weight-heavy: 900;

$font-size-regular-xxl: 64px;
$font-size-regular-xl: 22px;
$font-size-regular-l: 20px;
$font-size-regular-m: 16px;
$font-size-regular-ms: 15px;
$font-size-regular-s: 14px;
$font-size-regular-xs: 12px;

$line-height-l: 72px;
$line-height-ml: 32px;
$line-height-m: 24px;
$line-height-s: 16px;
