// ===
// Design variables and utilities from src/design.
// ===
@import 'design';
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap&subset=cyrillic');
//@import url('https://unicons.iconscout.com/release/v2.0.1/css/unicons.css');
@import 'floating-vue/dist/style.css';




html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    padding: 0;
    margin: 0;
    font: inherit;
    font-size: 100%;
    line-height: 1.4;
    vertical-align: baseline;
    //border: 0;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: '';
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

// ===
// Base element styles
// ===
html {
    height: 100vh;
}

body {
    height: 100vh;
    overflow: auto;
    font-family: $system-default-font-family;
    font-size: $font-size-regular-m;
    line-height: $line-height-m;
    color: $color-text-grey;
    background: $color-body-bg;
}

a {
    color: $color-text-grey;   //TODO change link color
    text-decoration: none;
}

//h1 {
//    font-family: $heading-default-font-family;
//    font-size: $font-size-header-l;
//    font-weight: $font-weight-medium;
//}

//h2 {
//    margin-bottom: 20px;
//    font-family: $heading-default-font-family;
//    font-size: $font-size-header-m;
//    font-weight: $font-weight-medium;
//}

//p {
//    margin: 0 0 10px;
//}

button {
    cursor: pointer;
    outline: none;
}

strong {
    font-weight: $font-weight-bold;
}

.preloader-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: calc(100vh - 190px);
}

.ivu-input-wrapper {
    .ivu-input {
        width: 100%;
        height: auto;
        padding: 16px 24px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: $color-text-grey;
        border: 1px solid $color-border-light-blue;
        border-radius: 4px;

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #cacaca;
        }
    }

    textarea.ivu-input {
        min-height: 112px;
        font-family: inherit;
        resize: none;
        &:focus-visible {
            outline: 1px solid $color-blue
        }
        &.has-errors {
            border-color: $color-border-red;
            &:focus-visible {
                outline: 1px solid $color-red
            }
        }
    }
}


// flatpickr calendar
.flatpickr-input {
        display: inline-block;
        width: 100%;
        height: auto;
        padding: 16px 24px;
        font-size: 16px;
        line-height: $line-height-m;
        color: $color-text-grey;
        border: 1px solid $color-border-light-blue;
        border-radius: 6px;
        &.active {
            margin: -1px;
            border: 2px solid $color-border-light-blue;
            border-radius: 4px 4px 0 0;
        }

        &::placeholder {
            font-size: $font-size-regular-s;
            font-weight: $font-weight-medium-s;
            color: $color-light-grey;
        }
    }

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
    position: absolute;
    top: 152px;
    z-index: 2;
    box-sizing: border-box;
    width: 515px !important;
    max-height: 640px;
    //padding: 0 24px;
    margin-top: -5px;
    visibility: visible;
    border: 2px solid $color-border-light-blue;
    border-radius: 6px;
    box-shadow: 4px 4px 24px rgba(51, 51, 51, 0.08);
    opacity: 1;
}

.flatpickr-innerContainer {
    .flatpickr-days {
        display: flex;
        justify-content: space-around;
        //width: 807px !important;
        .dayContainer {
            min-width: 220px !important;
            max-width: 220px !important;
            .startRange,
            .endRange,
            .inRange, {
                color: $color-white;
                background: $color-blue;
                border-color: $color-blue;
                box-shadow: 0 0 0 $color-blue, 0 0 0 $color-blue;
            }
            .startRange {
                border-radius: 8px 0 0 8px;
            }
            .endRange {
                border-radius: 0 8px 8px 0;

            }
        }
    }
}

.dayContainer + .dayContainer {
    box-shadow: none !important;
}

.flatpickr-weekdays {
    display: flex;
    justify-content: space-around;
    //width: 514px;
    .flatpickr-weekdaycontainer {
        max-width: 220px;
        .flatpickr-weekday {
            font-size: $font-size-regular-xs;
            font-weight: $font-weight-medium-s;
            line-height: 22px;
            color: $color-text-grey;
        }
    }
}

.flatpickr-calendar {
    display: none;
    box-shadow: none !important;

    &.open {
        display: block;
        //max-width: 515px;
    }


    &.flatpickr-calendar-months {
        //max-width: 807px;
        &.wide {
            top: 436px !important;
            width: 807px !important;
        }
    }

    &.arrowBottom,
    &.arrowTop {
        &::before,
        &::after {
            content: none;
        }
    }

    .flatpickr-months {
        height: 43px;

        .flatpickr-month {
            z-index: 1;
            height: 40px;
            overflow: visible;
        }

        .flatpickr-prev-month,
        .flatpickr-next-month {
            position: absolute;
            top: 2%;
            height: 40px;
            padding: 12px;

            svg {
                width: 16px;
                height: 16px;
                border: 2px;
                fill: #222 !important;
            }
        }
    }

    .flatpickr-rContainer {
        width: 100%;
        margin: auto;
    }

    .dayContainer {
        justify-content: start;
        padding-bottom: 10px;
        .flatpickr-day {
            max-width: 32px;
            height: 31px;
            font-size: 12px;
            line-height: 28px;
        }
    }

    .flatpickr-days {
        z-index: 0;
        width: 100% !important;
    }

}
.flatpickr-calendar.open.wide {
    .flatpickr-innerContainer {
        .flatpickr-weekdays {
            width: 807px;
            .flatpickr-weekdaycontainer {
                max-width: 360px;
                .flatpickr-weekday {
                    font-size: $font-size-regular-s;
                    font-weight: $font-weight-light;
                }
            }
        }
        .flatpickr-days {
            width: 807px !important;

            .dayContainer {
                width: 360px !important;
                min-width: 360px !important;
                max-width: 360px !important;
                .flatpickr-day {
                    width: 48px;
                    max-width: 52px;
                    font-size: $font-size-regular-s;
                }
            }
        }
    }
}

.flatpickr-current-month {
    //display: flex !important;
    //align-items: center;
    //justify-content: center;
    padding-top: 14px;
    font-size: $font-size-regular-s !important;
    line-height: 34px !important;
    .cur-month {
        font-weight: 400 !important;
        color: $color-text-grey;
    }

    .numInputWrapper {
        display: block;
    }

    .flatpickr-monthDropdown-months {
        display: none;
    }

    .flatpickr-year-select {
        margin-left: 20px;

        &:focus {
            outline: none;
        }

        select {
            cursor: pointer;
            border: none;
        }
    }
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
    background: $color-border-light-blue;
    border-color: $color-border-light-blue;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)) {
    box-shadow: 0 0 0 $color-blue, 0 0 0 $color-blue !important;
}

//multiselect
.multiselect {
    &.is-active {
        margin: -1px;
        border: 2px solid $color-border-light-blue !important;
        border-radius: 4px 4px 0 0 !important;
        box-shadow: none !important;
    }

    padding: 12px 24px;
    border: 1px solid $color-border-light-blue !important;
    border-radius: 6px !important;
    .multiselect-wrapper {
        min-height: 32px;
        .multiselect-tags {
            padding-left: 0;
            .multiselect-tags-search-wrapper {
                margin-left: 0;
            }
            .multiselect-tag {
                margin-right: 8px;
                color: $color-blue;
                background: $color-transparent-blue;
            }
        }
        .multiselect-placeholder {
            padding-left: 0;
            font-size: $font-size-regular-s;
            font-weight: $font-weight-medium-s;
            color: $color-light-grey;
        }
        .multiselect-caret {
            width: 24px;
            height: 24px;
            margin: 0;
            background: $color-blue;
            -webkit-mask-image: url('~@assets/pagination-arrow.svg');
            transform: rotate(90deg);
            &.is-open {
                transform: rotate(-90deg);
            }
        }
    }
    .multiselect-dropdown {
        margin: -1px;
        border: 2px solid $color-border-light-blue;
        .multiselect-option {
            padding-left: 24px;
            &.is-selected {
                color: $color-text-grey;
                background: $color-lite-blue-bg;
            }
            &.is-pointed {
                background: $color-transparent-blue;
            }
        }
    }
    .multiselect-clear-icon,
    .multiselect-clear:hover .multiselect-clear-icon {
        width: 20px;
        height: 20px;
        background: $color-blue;
    }

    /* .multiselect-clear:hover .multiselect-clear-icon {
        background: $color-blue;
    } */
}
.label {
    margin-left: 25px;
    font-size: $font-size-regular-xs;
    font-weight: $font-weight-medium-s;
    line-height: $line-height-m;
}
.red {
    margin-left: 4px;
    color: $color-text-red;
}

//table icons

.table-controls {
    display: none;
    align-items: center;
    cursor: pointer;
    > * {
        width: 18px;
        height: 18px;
        margin-right: 20px;
        &:first-child {
            margin-left: 10px;
        }
        &:first-child:hover {
            margin-left: 3px;
        }
    }
    > *:hover {
        width: 32px;
        height: 32px;
        margin-right: 13px;
        margin-left: -7px;
    }
    .view {
        background: url('~@assets/eye.svg') no-repeat center/contain;
        &:hover {
            background: url('~@assets/eye_hover.svg') no-repeat center/contain;
        }
    }
    .edit {
        background: url('~@assets/edit.svg') no-repeat center/contain;
        &:hover {
            background: url('~@assets/edit_hover.svg') no-repeat center/contain;
        }
    }
    .trash {
        background: url('~@assets/trash-black.svg') no-repeat center/contain;
        &:hover {
            background: url('~@assets/trash-blue.svg') no-repeat center/contain;
        }
    }
    .minus-circle {
        background: url('~@assets/minus-circle-black.svg') no-repeat center/contain;
        &:hover {
            background: url('~@assets/minus-circle-blue.svg') no-repeat center/contain;
        }
    }
    .archive {
        background: url('~@assets/archive.svg') no-repeat center/contain;
        &:hover {
            background: url('~@assets/archive_hover.svg') no-repeat center/contain;
        }
    }
    .restore {
        background: url('~@assets/restore.svg') no-repeat center/contain;
        &:hover {
            background: url('~@assets/restore_hover.svg') no-repeat center/contain;
        }
    }
    .transfer {
        background: url('~@assets/truck.svg') no-repeat center/contain;
        &:hover {
            background: url('~@assets/truck_hover.svg') no-repeat center/contain;
        }
    }
    .return {
        background: url('~@assets/truck-back.svg') no-repeat center/contain;
        &:hover {
            background: url('~@assets/truck-back_hover.svg') no-repeat center/contain;
        }
    }
}

//tooltip

.v-popper--theme-my-theme .v-popper__inner {
    padding: 8px 16px;
    color: $color-text-grey;
    background: $color-white;
    border: none;
    border-radius: 6px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.v-popper--theme-my-theme .v-popper__arrow-outer {
    border-color: $color-white;
}

.v-popper--theme-my-theme .v-popper__arrow-inner {
    visibility: hidden;
}

//info-slot
.info-name {
    margin-left: 13px;
    font-family: 'Nurito', sans-serif;
    font-size: 20px;
    font-weight: 500;
}

.info-user {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 16px;

    .info-label {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 500;
    }

    .info-value {
        font-size: 16px;
        font-weight: 500;
    }

    .info-item {
        &:nth-last-child(2) {
            span {
                display: none;
            }
        }
    }
}
.divider {
    width: 100%;
    border-bottom: 1px solid $color-border-light-blue;
}

// close

.close-button {
    position: absolute;
    top: 27px;
    right: 23px;
    width: 20px;
    height: 30px;
    margin-left: 14px;
    cursor: pointer;
    border: none;

    &::after,
    &::before {
        position: absolute;
        width: 2px;
        height: 18px;
        content: '';
        background-color: $color-text-grey;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

// table

.table {
    position: relative;
    width: 100%;
    table-layout: fixed;

    &-empty {
        height: 100%;
        min-height: fit-content;
    }

    &-cell {
        display: flex;
        justify-content: space-between;
        margin: 3px;
        overflow: hidden;
        &-container {
            flex-basis: auto;
            overflow: hidden;
        }
    }
    .sort-icon {
        display: inline-flex;
        align-self: center;
        width: 18px;
        margin: 0 8px;
    }

    &-column {
        padding: 0 0 0 17px;
        text-align: left;
        white-space: nowrap;
        vertical-align: middle;
        span {
            display: block;
            padding: 6px;
            overflow: hidden;
            font-size: $font-size-regular-s;
            font-weight: $font-weight-medium-s;
            line-height: $line-height-m;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &-sortable {
            position: relative;
            cursor: pointer;
        }
    }

    &-header {
        .table-column {
            padding: 8px 0 8px 17px;
            border-bottom: 1px solid $color-border-light-blue;
            span {
                overflow: hidden;
                font-weight: $font-weight-medium;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .table-cell {
                justify-content: flex-start;
            }
        }
        th {
            position: sticky;
            top: 0;
            z-index: 4;
            background: $color-default-bg;
            box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.08);
        }
    }

    &-row {
        position: relative;
        padding: 15px 120px 15px 20px;

        span {
            display: inline-block;
            img {
                vertical-align: middle;
            }
        }

        &:last-child {
            border-bottom: solid 1px rgba($color-text-grey, 0.1);
        }

        &:nth-child(2n + 1) {
            background: $color-table-lite-blue-bg;
        }

        &-clickable {
            cursor: pointer;

            &:hover {
                box-shadow: 4px 4px 24px rgba(51, 51, 51, 0.08);
                .table-controls {
                    display: flex;
                }
            }
        }
        &:hover {
            .table-controls {
                display: flex;
            }
        }
    }

    .empty-block {
        position: absolute;
        top: calc(50% - 130px);
        width: 100%;
        text-align: center;

        p {
            color: rgba($color-text-grey, 0.6);
        }
    }

    &-sort {
        margin-left: 20px;
    }
}
